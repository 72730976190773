import React from "react"
import Link from "gatsby-link"

export default function AlternatingCopy(props) {
  return (
  	<section className="alternating-copy">
	  	<div className="container">
        <div className="alternating-copy__row">
  	  		<div className="alternating-copy__left">
  	  			<h2 data-aos="fade-right">
    					<span role="img" aria-label="tools">🛠</span> Web Development
    				</h2>
    				<p data-aos="fade-right">Give me a design, brief or even your thoughts on a napkin and we can work together in bringing your website to life.</p>
            <p data-aos="fade-right">It doesn't matter if it's a one-pager, a simple brochure site or an ecommerce site, I can build it.</p>
            <ul className="tags" data-aos="fade-right">
              <li className="tag">WordPress</li>
              <li className="tag">ExpressionEngine</li>
              <li className="tag">React</li>
              <li className="tag">Gatsby</li>
              <li className="tag">Netlify</li>
            </ul>
  	  		</div>
  	  		<div className="alternating-copy__right"></div>
  	  	</div>
        <div className="alternating-copy__row">
          <div className="alternating-copy__left">
            <h2 data-aos="fade-right">
              <span role="img" aria-label="painter">👨🏻‍🎨</span> Web Design
            </h2>
            <p data-aos="fade-right">Many years of building websites has given me enough insight and understanding to start designing them too.</p>
            <p data-aos="fade-right">Taking into consideration the latest accessibility standards, UX practices, and the latest design trends, I'll work with you to get a design ready for build.</p>
            <ul className="tags" data-aos="fade-right">
              <li className="tag">Adobe XD</li>
              <li className="tag">UX Focused</li>
              <li className="tag">Accessibility</li>
            </ul>
          </div>
          <div className="alternating-copy__right"></div>
        </div>
        <div className="alternating-copy__row">
          <div className="alternating-copy__left">
            <h2 data-aos="fade-right">
              <span role="img" aria-label="filing folders">🗂</span> Web Hosting
            </h2>
            <p data-aos="fade-right">After speaking to you and figuring out what kind of solution is best for your business and website, I will find the right hosting solution for your site, and provide the maintenance and upkeep so you can forget that hosting is even a thing.</p>
            <p data-aos="fade-right">Everything I host has its own virtual server, which includes but is not limited to: automated backups, uptime monitoring, scalable resources (increased bandwidth, power, whatever it needs), and more.</p>
            <ul className="tags" data-aos="fade-right">
              <li className="tag">High Performance</li>
              <li className="tag">Scalable</li>
              <li className="tag">Personal Support</li>
              <li className="tag">Cost Effective</li>
            </ul>
          </div>
          <div className="alternating-copy__right"></div>
        </div>
      </div>
  	</section>
  	)
}