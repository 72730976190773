import React from "react"
import AOS from "aos"
import SEO from "../components/seo"
import HeroSmall from "../components/hero--sm"
import AlternatingCopy from "../components/alternating-copy"
import CTA from "../components/cta"

class About extends React.Component {
    componentDidMount() {
  		AOS.init({
  			duration: 800,
  			easing: 'easeOutCubic',
        anchorPlacement: 'top-bottom'
  		});
    }

    componentDidUpdate() {
      AOS.refresh();
    }

    render() {
        return(
          <div>
            	<SEO title="What I do" description="Give me a design, brief or even your thoughts on a napkin and we can work together in bringing your website to life." />
		  		    <HeroSmall heading="👨🏼‍💻 What I do"/>
              <AlternatingCopy/>
              <CTA/>
		  	  </div>
        )
    }
}

export default About